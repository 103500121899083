.btn {
  height: 40px;
  cursor: pointer;
  border-radius: 2px;
}

.btn.btn-fullwidth {
  width: 100%;
  text-align: center;
}

.btn.btn-secondary {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #8e83fc;
  border: solid 1px #e5e5e5 !important;
  border-radius: 2px;
  background-color: #fff;
}

.btn.btn-secondary svg * {
  fill: #8e83fc;
}

.btn.btn-secondary.btn-lg {
  font-weight: 500;
  line-height: 40px;
  background: 0 0;
}

.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
  outline: 0;
  background-color: #fff;
  box-shadow: none;
}

.btn.btn-secondary.btn-md {
  font-size: 14px !important;
  font-weight: 500;
  color: #7235fd;
  border: 0 !important;
  background-color: #fff;
  box-shadow: 0 3px 9px 0 rgba(43, 38, 49, 0.12);
}

.btn.btn-secondary.btn-md:focus,
.btn.btn-secondary.btn-md:hover {
  color: #7235fd;
  background-color: #f3f3f3;
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-secondary.btn-md:active {
  background-color: #e1e1e1;
  box-shadow: 0 7px 15px 0 rgba(130, 77, 191, 0.09);
}

.btn.btn-whitebg {
  font-size: 14px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0;
  color: #8e83fc;
  border-color: #fff;
  border-radius: 2px;
  background: #fff;
}

.btn.btn-whitebg svg * {
  fill: #8e83fc;
}

.btn.btn-whitebg:focus,
.btn.btn-whitebg:hover {
  outline: 0;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: none;
}

.btn.btn-more {
  font-size: 14px;
  font-weight: 300;
  height: 24px;
  padding: 0 14px 0 0;
  letter-spacing: 0.1px;
  color: #7235fd;
  background: 0 0;
}

.btn.btn-more.dropdown-toggle {
  width: 24px;
  padding: 0;
  text-align: center;
  border: solid 1px #e1e1e1;
  border-radius: 2px;
}

.btn.btn-more.dropdown-toggle:active,
.btn.btn-more.dropdown-toggle:hover {
  background: #fff;
}

.show .btn.btn-more.dropdown-toggle {
  background: #fff;
  box-shadow: none;
}

.btn.btn-default {
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
  border: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-default span {
  color: #fff !important;
}

.btn.btn-default:focus,
.btn.btn-default:hover {
  outline: 0;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-default:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 10px 0 rgba(130, 77, 191, 0.09);
}

.btn.btn-default.disabled,
.btn.btn-default:disabled {
  color: #aaa !important;
  background-color: #e6e6e6 !important;
  background-image: none !important;
  box-shadow: none;
}

.btn.btn-default.disabled svg *,
.btn.btn-default:disabled svg * {
  fill: #aaa !important;
}

.btn.btn-primary {
  color: #fff !important;
  border: 0 !important;
  background-color: #ffaa5c !important;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.21),
    0 2px 13px 0 rgba(255, 170, 92, 0.47) !important;
}

.btn.btn-primary:focus,
.btn.btn-primary:hover {
  outline: 0;
  background-color: #ffb46f !important;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.21),
    0 2px 13px 0 rgba(255, 170, 92, 0.47);
}

.btn.btn-primary:active {
  background-color: #ee9e55 !important;
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.06),
    0 2px 10px 0 rgba(255, 170, 92, 0.2);
}

.btn.btn-primary .disabled,
.btn.btn-primary:disabled {
  color: #aaa !important;
  background-color: #e6e6e6 !important;
}

.btn.btn-primary svg * {
  fill: #fff;
}

.btn.btn-outline-success {
  line-height: 38px;
  color: #fff;
  border: solid 1px rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
}

.btn.btn-outline-success:focus,
.btn.btn-outline-success:hover {
  color: #3c4b69;
  border: 1px solid transparent;
  outline: 0;
  background-color: #f5f5f5;
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-outline-success:active {
  color: #3c4c69;
  background-color: #e1e1e1;
  box-shadow: 0 7px 15px 0 rgba(130, 77, 191, 0.09);
}

.btn.btn-outline-success .disabled,
.btn.btn-outline-success:disabled {
  color: #aaa;
  background-color: #e6e6e6;
}

.btn.btn-outline-success-black {
  line-height: 38px;
  color: #364c69;
  border: solid 1px #364c69 !important;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.1);
}

.btn.btn-outline-success-black:focus,
.btn.btn-outline-success-black:hover {
  color: #3c4b69;
  border: 1px solid transparent !important;
  outline: 0;
  background-color: #f5f5f5;
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-md {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px !important;
  height: 40px;
  padding: 8px 20px;
  letter-spacing: 0;
}

.btn.btn-md.right-icon span {
  line-height: 24px;
}

.btn.btn-md.left-icon svg {
  line-height: 24px;
  height: 24px;
}

.btn.btn-md.left-icon span {
  line-height: 24px;
}

.btn.btn-lg {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px !important;
  height: 56px;
  padding: 8px 20px;
  letter-spacing: 0.6px;
}

.btn.btn-cat {
  font-size: 17px;
  line-height: 1.24;
  display: -ms-flexbox;
  display: flex;
  width: 226px;
  height: 80px;
  padding-right: 14px;
  padding-left: 14px;
  text-align: left;
  white-space: normal;
  letter-spacing: -0.1px;
  color: #364c69;
  border: 0;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.27);
}

.btn.btn-cat span {
  line-height: 1.3 !important;
  width: calc(100% - 50px);
}

.btn.btn-cat svg {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.btn.btn-cat.active,
.btn.btn-cat:hover {
  transition: all 0.2s;
  color: #fff;
  background-image: linear-gradient(306deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.btn.btn-cat.active svg *,
.btn.btn-cat:hover svg * {
  fill: #fff;
}

.btn.btn-cat-lg {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.33;
  display: -ms-flexbox;
  display: flex;
  width: 481px;
  height: 168px;
  padding-left: 30px;
  transition: all 0.2s;
  text-align: left;
  white-space: normal;
  color: #fff;
  border: 0;
  border-radius: 10px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.btn.btn-cat-lg .genre-title {
  font-size: 36px !important;
  line-height: 1.33;
  font-weight: 600;
  color: #fff;
  text-align: left;
  margin: 0;
  transition: 0.5s all;
}

.btn.btn-cat-lg:hover {
  background-size: 110% 110%;
}

.btn.btn-link {
  font-size: 16px;
  height: 40px;
  padding: 0;
  color: #ac74fe !important;
}

.btn.btn-link span {
  color: #ac74fe !important;
}

.btn.btn-link:focus,
.btn.btn-link:hover {
  text-decoration: none;
  color: #7170cb !important;
  outline: 0 !important;
  box-shadow: none;
}

.btn.btn-link:focus span,
.btn.btn-link:hover span {
  color: #7170cb !important;
}

.btn.btn-link:focus svg *,
.btn.btn-link:hover svg * {
  fill: #7170cb !important;
}

.btn.btn-link.text-white.text-white {
  color: #fff !important;
}

.btn.btn-link.text-white.text-white span {
  color: #fff !important;
}

.btn.btn-link.text-white.text-white:focus,
.btn.btn-link.text-white.text-white:hover {
  text-decoration: none;
  color: #fff !important;
  outline: 0 !important;
  box-shadow: none;
}

.btn.btn-link.text-white.text-white svg * {
  fill: #fff !important;
}

.btn.btn-price {
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.btn.btn-price .buy-now-text {
  display: inline-block;
  vertical-align: top;
}

.btn.btn-price .buy_now_price {
  display: inline-block;
  font-weight: 400;
  padding-left: 16px;
}

.btn.btn-price .buy_now_price .old-price {
  display: block;
  margin-top: 4px;
  font-size: 13px;
  line-height: 14px;
  text-decoration: line-through;
  opacity: 0.7;
}

.btn.btn-price .buy_now_price .new-price {
  display: block;
  line-height: 22px;
}

.btn.left-icon svg {
  line-height: 40px;
  float: left;
  margin-right: 16px;
}

.btn.left-icon svg#wave-show {
  fill: #ac74fe;
}

.btn.left-icon span {
  line-height: 40px;
}

.btn.left-icon span.btn-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.btn.left-icon span.btn-text svg {
  float: none;
  -ms-flex: none;
  flex: none;
  -ms-flex-order: -5;
  order: -5;
}

.btn.left-icon-2 svg {
  line-height: 40px;
  float: left;
  height: 40px;
  margin-right: 16px;
}

.btn.left-icon-2 span {
  line-height: 20px;
  display: block;
}

.btn.left-icon-2 span:first-of-type {
  font-size: 15px;
  font-weight: 400;
}

.btn.right-icon img,
.btn.right-icon svg {
  float: right;
  margin-left: 16px;
  vertical-align: middle;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn.right-icon span {
  line-height: 40px;
}

.btn.only-icon {
  line-height: 40px;
}

.btn br {
  display: none !important;
}

.nav-pills {
  margin-bottom: 40px;
  border-bottom: 2px solid #f4f4f4;
}

.custom-radio-button {
  line-height: 19px !important;
}

.custom-radio-button .radio-container {
  padding: 5px 10px;
  border: 1px solid #edeeef;
  border-radius: 5px;
}

.custom-radio-button .radio-container:hover {
  cursor: pointer;
}

.custom-radio-button .radio-container.selected {
  background-color: #edeeef;
}

.custom-radio-button div {
  display: inline-block;
  margin: 0 10px;
}

.custom-radio-button label {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: inherit;
  position: relative;
  margin-right: 16px;
  padding-left: 25px;
  color: rgba(112, 110, 129, 0.5);
  text-align: left;
}

.custom-radio-button label:before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  content: "";
  border: 1px solid #e5e5e5;
  border-radius: 100%;
}

.custom-radio-button label .radio-price {
  font-weight: 300;
  font-size: 13px;
}

.custom-radio-button label .radio-price.radio-regular-price {
  color: #b3bcc7;
  text-decoration: line-through;
  padding-right: 5px;
}

.custom-radio-button label .radio-price + .radio-price {
  font-weight: 400;
}

.custom-radio-button input {
  position: absolute;
  opacity: 0;
}

.custom-radio-button input:checked ~ label {
  color: #706e81;
}

.custom-radio-button input:checked ~ label:after {
  position: absolute;
  top: calc(50% - 5px);
  left: 4px;
  width: 8px;
  height: 4px;
  content: "";
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
}

.custom-radio-button input:checked ~ label:before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  content: "";
  border: 0;
  border-radius: 100%;
  background-image: linear-gradient(140deg, #ac74fe, #8e83fc);
  box-shadow: 0 8px 9px 0 rgba(146, 129, 252, 0.45);
}

.btn-link-icon {
  display: block;
  float: left;
  height: 32px;
}

.btn-link-icon:hover {
  text-decoration: none !important;
  opacity: 0.7;
}

.btn-link-icon:not(:last-child) {
  margin-right: 25px;
}

.btn-link-icon svg {
  height: 32px;
}

.custom-checkbox {
  line-height: 19px !important;
}

.custom-checkbox label {
  font-size: 15px !important;
  font-weight: 400;
  line-height: inherit !important;
  position: relative;
  float: left;
  margin-right: 16px;
  padding-left: 30px;
  color: rgba(54, 76, 105, 0.7);
}

.custom-checkbox label:before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  content: "";
  border: solid 2px #b3bcc7;
  border-radius: 2px;
  background: #fff;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
}

.custom-checkbox input:checked ~ label {
  color: #706e81;
}

.custom-checkbox input:checked ~ label:after {
  position: absolute;
  top: calc(50% - 6px);
  left: 4px;
  width: 10px;
  height: 6px;
  content: "";
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
}

.custom-checkbox input:checked ~ label:before {
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  content: "";
  border: 0;
  background-image: linear-gradient(140deg, #ac74fe, #8e83fc);
  box-shadow: 0 8px 9px 0 rgba(146, 129, 252, 0.45);
}

.dropdown-menu a {
  color: #364c69 !important;
  border-bottom: 0;
}

.variations {
  margin: 0 0 25px 0 !important;
}

.divider {
  display: inline-block;
  width: 2px;
  height: 40px;
  margin: 0 25px;
  vertical-align: middle;
  background: #e5e5e5;
}

.play-lesson .show-default,
.play-sample .show-default {
  display: block;
}

.play-lesson .show-active,
.play-sample .show-active {
  display: none;
}

.play-lesson.active .show-default,
.play-sample.active .show-default {
  display: none;
}

.play-lesson.active .show-active,
.play-sample.active .show-active {
  display: block;
}

.checkout-btn {
  float: right;
}

.single-product .woocommerce-message .button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  height: 40px;
  padding: 8px 20px;
  text-align: center;
  letter-spacing: 0;
  opacity: 1;
  color: #fff;
  border: 0;
  border-radius: 2px;
  background: linear-gradient(to left, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
  transition: opacity 0.3s ease-out;
  box-sizing: border-box;
}

.single-product .woocommerce-message .button:active,
.single-product .woocommerce-message .button:focus,
.single-product .woocommerce-message .button:hover {
  opacity: 0.7;
  color: #fff;
  background: linear-gradient(to left, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.woocommerce-pagination-wrapper {
  margin: 20px 0 10px;
  text-align: right;
}

.woocommerce-pagination-wrapper .paginations-v1-list,
.woocommerce-pagination-wrapper .paginations-v3-list {
  margin: 0;
}

.woocommerce-pagination-wrapper.ajax-pagination .paginations-v3-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.woocommerce-pagination-wrapper.ajax-pagination
  .paginations-v3-list
  .load-more {
  height: 40px;
  line-height: 25px !important;
  margin-left: 20px;
}

.woocommerce-pagination-wrapper.ajax-pagination
  .paginations-v3-list
  .load-more:hover {
  outline: 0;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.woocommerce-pagination-wrapper.ajax-pagination
  .paginations-v3-list
  .load-more:active,
.woocommerce-pagination-wrapper.ajax-pagination
  .paginations-v3-list
  .load-more:focus {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.woocommerce-pagination-wrapper ul.page-numbers {
  margin: 0;
  padding: 0;
}

.woocommerce-pagination-wrapper ul.page-numbers li {
  float: left;
  overflow: hidden;
  margin: 0;
  margin-left: -1px;
  text-align: center;
  border: 1px solid #e1e1e1;
}

.woocommerce-pagination-wrapper ul.page-numbers li:first-child {
  border-radius: 4px 0 0 4px;
}

.woocommerce-pagination-wrapper ul.page-numbers li:last-child {
  border-radius: 0 4px 4px 0;
}

.woocommerce-pagination-wrapper ul.page-numbers li a.page-numbers {
  font-size: 14px;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 38px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: #666;
  background: #fff;
}

.woocommerce-pagination-wrapper ul.page-numbers li a.page-numbers:hover {
  background: #ecedee;
}

.woocommerce-pagination-wrapper ul.page-numbers li .current {
  font-size: 14px;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 38px;
  margin: 0;
  padding: 0;
  color: #7235fd !important;
  background: #fff !important;
}

.woocommerce-pagination-wrapper ul.page-numbers li .dots {
  font-size: 14px;
  line-height: 38px;
  display: inline-block;
  width: 40px;
  height: 38px;
  margin: 0;
  padding: 0;
  color: #666;
  background: #fff;
}
