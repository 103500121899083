
/*start products*/

/*end products*/

.articles-number {
    opacity: 0.2;
    font-size: 112px;
    font-weight: bold;
    line-height: 1.29;
    color: #000000;
}

body .paginations-v3 li {
    margin: 0;
}

body .paginations-v3 li a {
    margin: 0 0 0 -1px;
    font-size: 14px;
    color: #666;
    padding: 9px 16px;
    border: solid 1px #e5e5e5;
    background: #fff;

    font-weight: 400;
}

body .paginations-v3 li:first-child a {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

body .paginations-v3 li:last-child a {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

body .paginations-v3 .paginations-v3-list > li.active > a {
    color: #7236fd;
    border: solid 1px #e5e5e5;
    background: #fff;
}

.logo-wrap::after,
.logo-wrap::before {
    background: #2b3c54 !important;
}

.navbar-logo-wrap .navbar-logo-img-normal {
    margin-top: -5px;
}

.navbar-logo-wrap .navbar-logo-img-fixed {
    margin-top: -5px;
}

.home-section-titlebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.sticky-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
}

/*start share buttons*/
.addtoany_list {
    display: block !important;
    margin: 10px 0;
}

.addtoany_list > a {
    padding: 0;
    margin: 0 5px;
}
.addtoany_list > a:empty {
    display: none;
}

.addtoany_list > a .a2a_svg {
    border-radius: 50%;
    background: none !important;
    border: solid 1px #e5e5e5;
    padding: 3px;
}

.addtoany_list > a:hover .a2a_svg {
    border: none;
    background-image: -webkit-linear-gradient(
            144deg,
            #549ff8,
            #ba6eff
    ) !important;
    background-image: -o-linear-gradient(144deg, #549ff8, #ba6eff) !important;
    background-image: -webkit-linear-gradient(
            144deg,
            #549ff8,
            #ba6eff
    ) !important;
    background-image: linear-gradient(306deg, #549ff8, #ba6eff) !important;
}

.addtoany_list > a .a2a_svg path {
    fill: #8e83fc;
}

.addtoany_list > a:hover .a2a_svg path {
    fill: #ffffff;
}

.vertical-share-wrap.share-all-wrapper .share-title {
    width: 100%;
    float: none;
    display: block;
    text-align: center;
    margin-top: 0;
}

.share-all-wrapper {
    clear: both;
}

.share-all-wrapper.border-on-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 16px;
}

.share-all-wrapper .share-title {
    float: left;
    margin: 20px 10px 20px 0;
    font-size: 14px;
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(54, 76, 105, 0.7);
}

.vertical-share-wrap.share-all-wrapper .share-wrapper .addtoany_list > a {
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.vertical-share-wrap.share-all-wrapper .share-wrapper .addtoany_list
> a
> span {
    height: 48px;
    line-height: 48px;
    width: 48px;
    padding: 7px;
}

.share-all-wrapper .share-wrapper {
    overflow: hidden;
}

/*end share buttons*/

/*start main menu*/
.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu > li {
    height: auto;
    padding: 0;
    margin: 0;
    position: static;
    clear: both;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu li {
    height: auto;
    padding: 0;
    margin: 0;
    position: static;
    clear: both;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu li.current-menu-item
> a {
    border: none !important;
    opacity: 1;
    color: #293a56 !important;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu li.current-menu-item.menu-item-has-children
> a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    position: relative;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu li.current-menu-parent
> a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    position: relative;
    color: #293a56 !important;
    opacity: 1;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu li .dropdown-menu {
    height: 60vh;
    overflow: auto;
    padding: 10px 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    background-image: url("../img/bg/manubg.png");
    -webkit-box-shadow: none;
    box-shadow: none;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu:after {
    content: '';
    position: absolute;
    width: 615px;
    z-index: -1;
    background-color: #fff;
    top: 0;
    left: 0;
    border-radius: 4px;
    bottom: 0;
    -webkit-box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.10);
    box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.10);
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu
> li .dropdown-submenu-child:after {
    position: static;
    float: right;
    font-size: 14px;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu > li > a {
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 1;
    color: #8e939a !important;
    text-transform: none;
    padding: 8px 15px 8px 25px;
    display: block;
    width: 100%;
    height: auto;
    min-width: 215px !important;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu > li a {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #9fa4aa !important;
    text-transform: none;
    padding: 8px 15px 8px 25px;
    display: block;
    width: 100%;
    height: auto;
    min-width: 400px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 1;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu
> li a:hover {
    color: #293a56 !important;
    background: none !important;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu
> li
> a:hover {
    color: #293a56 !important;
    background: none !important;
}

.navbar.mega-menu .menu-container .navbar-nav
> li
> .dropdown-menu
> li
> a.dropdown-submenu-child:after {
    color: #293a56 !important;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu {
    height: 60vh;
    border-radius: 0;
    padding: 10px 0;
    margin-left: -75px;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar.mega-menu .menu-container .navbar-nav > li > .dropdown-menu:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    top: -9px;
    left: 95px;
}

/*end main menu*/

.help-counter-col-item-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.help-counter-text-wrap {
    width: 37%;
    max-width: 37%;
}

.help-counter-text-wrap p {
    font-size: 24px !important;
    font-weight: 300;
    line-height: 1.25;
    text-align: left;
    color: #364c69;
    padding-top: 30px;
}

.help-counter-item-wrap {
    width: 23%;
    max-width: 23%;
    padding-left: 40px;
}

.help-counter-item-wrap .counter {
    font-size: 64px !important;
    font-weight: 300 !important;
    line-height: 0.75;
    width: 100%;
    text-align: left;
    color: rgba(54, 76, 105, 0.85) !important;
    text-shadow: 0 7px 33px rgba(130, 77, 191, 0.19);
}

.loop-category-post-main-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    color: #364c69;
    margin-bottom: 30px;
}

.loop-category-post:not(:last-child) {
    border-bottom: solid 1px rgba(151, 151, 151, 0.15) !important;
}

.loop-category-post .loop-category-name {
    margin-left: 20px;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(54, 76, 105, 0.9);
    margin-bottom: 10px;
}

.loop-category-post .loop-category-name:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-image: -webkit-linear-gradient(310deg, #ac74fe, #8e83fc);
    background-image: -o-linear-gradient(310deg, #ac74fe, #8e83fc);
    background-image: linear-gradient(140deg, #ac74fe, #8e83fc);
    border-radius: 50%;
    top: 9px;
    left: -13px;
}

.loop-category-post .loop-category-content {
    margin-left: 20px;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(54, 76, 105, 0.8);
}

.loop-category-post .loop-category-content strong {
    font-weight: 500;
}

.epkb-search {
    position: relative;
}

.epkb-search-form-1 .loading-spinner {
    top: 14px;
    left: 12px;
}

.epkb-search .epkb-search-box {
    z-index: 1;
}

#epkb_search_results ul li {
    padding: 0 !important;
    margin: 5px 0;
}

#epkb_search_results ul {
    margin: 15px 17px 10px 17px;
    padding: 0;
}

#epkb_search_results ul li a {
    display: block;
    padding: 8px;
}

.epkb-doc-search-container #epkb_search_results ul li a .epkb-article-title span {
    margin: 0 !important;
}

.epkb-doc-search-container #epkb_search_results ul li a .eckb-article-title span {
    margin: 0 0 0 7px !important;
}

.epkb-doc-search-container #epkb_search_results ul li a .epkb-article-title .ep_icon_document {
    display: none;
}

.epkb-doc-search-container #epkb_search_results ul li a .eckb-article-title .ep_font_icon_document {
    display: none;
}

#epkb_search_results ul li a .epkb-article-title {
    display: block;
    position: relative;
}

#epkb_search_results ul li a:hover {
    opacity: 0.75;
}

#epkb_search_results {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 4px;
    top: 100%;
    margin-top: -5px;
    z-index: 0;
}

#epkb_search_results h3 {
    display: none;
    margin: 10px 25px 5px 25px;
}

.epkb-doc-search-container h2 {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #ffffff;
    margin-bottom: 25px;
}

.tax-epkb_post_type_1_category .epkb-doc-search-container h2 {
    display: none;
}

.single-epkb_post_type_1 .epkb-doc-search-container h2 {
    display: none;
}

.page .loop-category-post .loop-category-name {
    display: none;
}

.page .loop-category-post .loop-category-content {
    margin: 0;
}

.single-epkb_post_type_1 .loop-category-post .loop-category-name {
    display: none;
}

.single-epkb_post_type_1 .loop-category-post .loop-category-content {
    margin: 0;
}

.epkb-doc-search-container {
    border: none;
    padding-bottom: 100px;
    padding-top: 125px;
}

.epkb-search {
    width: 100%;
}

#epkb_search_form.epkb-search-form-1 input[type=text],
#epkb_search_form.epkb-search-form-1 input[type=search] {
	display: block;
	width: 100%;
	padding-left: 5px;
    padding-right: 165px !important;
    font-size: 16px;
    color: rgba(54, 76, 105, 0.6);
    border: none !important;
    height: 50px;
    border-radius: 4px !important;
    background: #fff;
    -webkit-box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.epkb-search-form-1 button#epkb-search-kb {
	position: absolute;
    text-transform: uppercase;
    border: none !important;
    font-size: 16px;
    font-weight: 500;
    height: 42px;
    top: 4px;
    right: 5px;
    text-shadow: none;
    color: #ffffff;
    border-radius: 4px;
    background-image: -webkit-linear-gradient(167deg, #549ff8, #ba6eff);
    background-image: -o-linear-gradient(167deg, #549ff8, #ba6eff);
    background-image: linear-gradient(283deg, #549ff8, #ba6eff);
    -webkit-box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
    z-index: 3;
}

.epkb-search-form-1 button#epkb-search-kb:hover,
.epkb-search-form-1 button#epkb-search-kb:focus {
    outline: 0;
    background-image: -webkit-linear-gradient(
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
    ),
    -webkit-linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    -webkit-linear-gradient(165deg, #549ff8, #ba6eff);
    background-image: -o-linear-gradient(
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
    ),
    -o-linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    -o-linear-gradient(165deg, #549ff8, #ba6eff);
    background-image: linear-gradient(
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
    -webkit-box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

a[href].help-desk-home-category-link-wrap:hover {
    -webkit-box-shadow: 0 7px 33px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 7px 33px 0 rgba(0, 0, 0, 0.1);
}

.help-desk-home-category-link-wrap {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    margin-bottom: 25px;
    border-radius: 6px;
    overflow: hidden;
    padding: 30px 35px;
}

.help-desk-home-category-link-wrap .content-svg-category-link-wrap {
    margin-right: 35px;
}

.help-desk-home-category-link-wrap .content-svg-category-link-wrap svg {
    width: 64px;
    height: auto;
}

.content-category-link-wrap {
    overflow: hidden;
}

.content-category-link-wrap h3 {
    font-size: 20px !important;
    font-weight: bold;
    text-align: left;
    color: #364c69 !important;
    margin-bottom: 10px;
}

.content-category-link-wrap p {
    font-size: 14px !important;
    line-height: 1.29;
    letter-spacing: -0.1px;
    text-align: left;
    color: rgba(54, 76, 105, 0.7) !important;
}

.static-wave-bg {
    width: 100% !important;
    margin-top: -190px;
    height: auto;
    margin-bottom: -60px;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    -o-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.main-content-text-experience-sample {
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: -.1px;
    color: #fff;
    margin-bottom: 30px
}

.main-title-experience-sample {
    font-size: 32px;
    font-weight: 700;
    line-height: .66;
    letter-spacing: -.2px;
    color: #fff;
    margin-bottom: 30px
}

.experience-sample-page-row-align-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.link-wrap {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 215px;
}

.link-wrap.link-wrap-prev {
    float: right;
}

.link-wrap.link-wrap-next {
    float: left;
}

.link-wrap.link-wrap-prev:hover .icon-nav {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
}

.link-wrap.link-wrap-next:hover .icon-nav {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.link-wrap:hover .icon-wrap {
    border-color: #fff;
}

.link-wrap .icon-wrap {
    height: 142px;
    padding: 5px;
    width: 142px;
    border-radius: 6px;
    border: solid 1px rgba(255, 255, 255, 0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: border-color 0.3s ease;
    -o-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
}

.main-icon-experience-sample {
    height: 120px !important;
    width: 100% !important;
    margin: 0 auto 25px auto
}

.main-icon-experience-sample svg {
    height: 120px !important;
    width: 100% !important;
    margin: 0 auto
}

.main-icon-experience-sample path {
    fill: #fff !important
}

.main-icon-experience-sample svg path {
    fill: #fff !important
}

.main-icon-experience-sample rect {
    fill: #fff !important
}

.main-icon-experience-sample svg rect {
    fill: #fff !important
}

.link-wrap .icon-wrap svg {
    height: 40px !important;
    width: 100% !important;
    margin: 10px auto;
}

.link-wrap .icon-wrap h4 {
    font-size: 17px;
    line-height: 1.24;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin: 5px auto;
}

.link-wrap .icon-wrap svg path {
    fill: #fff !important;
}

.link-wrap .icon-wrap svg rect {
    fill: #fff !important;
}

.link-wrap .icon-nav {
    margin: 0 25px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.player-position-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 88px;
}

.product-program-on-one-exp-wrap-bg {
    overflow: hidden;
}

.album-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: relative;
    top: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.album-card {
    position: relative;
    border-radius: 6px;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.album-card:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
}

.album-card.card-size-sm {
    height: 116px;
    width: 116px;
    -webkit-box-shadow: 0 21px 40px 0 rgba(45, 52, 64, 0.43);
    box-shadow: 0 21px 40px 0 rgba(45, 52, 64, 0.43);
}

.album-card.card-size-md {
    height: 165px;
    width: 165px;
    -webkit-box-shadow: 0 21px 40px 0 rgba(45, 52, 64, 0.19);
    box-shadow: 0 21px 40px 0 rgba(45, 52, 64, 0.19);
}

.album-card.card-size-lg {
    height: 182px;
    width: 182px;
    -webkit-box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19),
    0 13px 20px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19),
    0 13px 20px 0 rgba(0, 0, 0, 0.5);
}

.album-card.card-size-lg h2 {
    font-size: 72px;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin: 15px auto 5px auto;
    line-height: 1;
}

.album-card.card-size-lg h3 {
    font-size: 24px;
    letter-spacing: -0.1px;
    color: #ffffff;
    margin: 0;
    line-height: 1;
}

.album-card.card-size-lg h4 {
    font-size: 16px;
    color: #ffffff;
    margin: 10px auto 0 auto;
    line-height: 1;
}

.product-action-on-one-exp-wrap-bg {
    min-height: 540px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-cover-on-one-exp {
    height: 285px;
    width: 285px;
    border-radius: 5px;
}

.product-action-on-one-exp-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.custom-expir-child-svg {
    margin: 0 auto 40px auto;
}

.custom-expir-caption-onbg h2.fg-text-light {
    font-weight: 400;
    line-height: 1.5;
    font-size: 32px;
}

.custom-expir-caption-onbg h3.fg-text-light {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.33;
}

.custom-expir-bg {
    min-height: 480px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.expire-action-btn {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100% !important;
    margin-bottom: 25px;
    position: relative;
}

.btn.btn-cat.expire-action-btn svg {
    max-width: 40px !important;
    width: 100% !important;
}

.btn.btn-cat.expire-action-btn svg .needfillcolor {
    fill: #967ffc;
}

.btn.btn-cat.expire-action-btn:hover svg .needfillcolor {
    fill: #ffffff;
}

.btn.btn-cat.expire-action-btn #customstyle rect {
    stroke: #967ffc;
}

.btn.btn-cat.expire-action-btn #customstyle path {
    stroke: #967ffc;
}

.btn.btn-cat.expire-action-btn #customstyle #arrowhere {
    fill: #967ffc !important;
}

.btn.btn-cat.expire-action-btn #customstyle #circlehere {
    stroke: #967ffc !important;
}

.btn.btn-cat.expire-action-btn:hover #customstyle rect {
    stroke: #ffffff;
}

.btn.btn-cat.expire-action-btn:hover #customstyle path {
    stroke: #ffffff;
}

.btn.btn-cat.expire-action-btn:hover #customstyle #arrowhere {
    fill: #ffffff !important;
}

.btn.btn-cat.expire-action-btn:hover #customstyle #circlehere {
    stroke: #ffffff !important;
}

.expiriance-main-header-wrap {
    margin-top: 115px;
    margin-bottom: 60px;
}

.research-papers-feature-section h2.base-content-title {
    font-size: 32px;
    line-height: 1.5;
    color: #ffffff;
    margin: 0;
}

.three-news-row {
    padding: 0 20px;
    clear: both;
}

.research-papers-feature-section h4.base-content-title.fg-text-light {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    margin: 0 0 35px 0;
    color: rgba(255, 255, 255, 0.7) !important;
}

.research-card-wrap {
    height: 370px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 1;

    position: relative;
    border-top-right-radius: 7px;
    padding: 30px 30px 30px 30px;
    border-top-left-radius: 7px;
    overflow: initial;
    background-image: -webkit-linear-gradient(
            320deg,
            #ffffff 47%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: -o-linear-gradient(
            320deg,
            #ffffff 47%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: linear-gradient(
            130deg,
            #ffffff 47%,
            rgba(255, 255, 255, 0.00) 100%
    );
    -webkit-box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
    margin-right: 25px;
}

.research-card-wrap .bg-one {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 20px;
    right: -15px;
    display: block;
    z-index: -1;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    opacity: 0.5;
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-linear-gradient(
            top,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: -o-linear-gradient(
            top,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: linear-gradient(
            -180deg,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    -webkit-box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
}

.research-card-wrap .bg-two {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 40px;
    right: -25px;
    display: block;
    z-index: -1;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    opacity: 0.12;
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-linear-gradient(
            top,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: -o-linear-gradient(
            top,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(50%, #ffffff),
            to(rgba(255, 255, 255, 0.00))
    );
    background-image: linear-gradient(
            -180deg,
            #ffffff 50%,
            rgba(255, 255, 255, 0.00) 100%
    );
    -webkit-box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.24);
}

.research-card-wrap:hover .bg-one {
    -webkit-transform: translate3d(0px, 10px, 0px);
    transform: translate3d(0px, 10px, 0px);
}

.research-card-wrap:hover .bg-two {
    -webkit-transform: translate3d(0px, 20px, 0px);
    transform: translate3d(0px, 20px, 0px);
}

.research-card-wrap .svg-img {
    position: absolute;
    height: 200px;
    bottom: -35px;
    right: 16px;
    pointer-events: none;
    fill-opacity: 0.8;
}

.research-papers-feature-section {
    overflow: hidden;
    padding-top: 25px;
}

.research-card-wrap.bordered {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: solid 2px rgba(0, 0, 0, 0.2);
    border-left: solid 2px rgba(0, 0, 0, 0.2);
    border-right: solid 2px rgba(0, 0, 0, 0.2);
    background: none;
}

.research-card-wrap h3.fg-heading {
    font-size: 14px;
    font-weight: 500;
    color: #b3bcc7;
}

.research-card-wrap .fg-bg {
    top: 6px;
}

.research-card-wrap .fg-bg .fg-bg-layer {
    background-position: right -6px !important;
    background-size: cover !important;
}

.research-card-wrap h2.fg-heading.fg-text-dark {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    color: #364c69;
}

.research-card-wrap h2.fg-heading.fg-text-light {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    color: #ffffff;
}

.fullwidth-image.right-shadow:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;

    opacity: 0.3;
    background: #000000;
    background-image: -webkit-linear-gradient(
            63deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
    background-image: -o-linear-gradient(
            63deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
    background-image: linear-gradient(
            27deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
}

.fullwidth-image.right-shadow:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;

    background-image: -webkit-linear-gradient(
            36deg,
            #549ff7 0%,
            #788dfa 36%,
            #b96eff 100%
    );

    background-image: -o-linear-gradient(
            36deg,
            #549ff7 0%,
            #788dfa 36%,
            #b96eff 100%
    );

    background-image: linear-gradient(
            54deg,
            #549ff7 0%,
            #788dfa 36%,
            #b96eff 100%
    );
}

.fullwidth-image.left-shadow:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;

    opacity: 0.3;
    background: #000000;
    background-image: -webkit-linear-gradient(
            117deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
    background-image: -o-linear-gradient(
            117deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
    background-image: linear-gradient(
            -27deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(110, 146, 249, 0.20) 26%,
            rgba(111, 146, 249, 0.20) 27%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
}

.fullwidth-image.left-shadow:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;

    background-image: -webkit-linear-gradient(
            70deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );

    background-image: -o-linear-gradient(
            70deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );

    background-image: linear-gradient(
            20deg,
            rgba(84, 159, 247, 0.20) 0%,
            rgba(120, 141, 250, 0.20) 36%,
            #b96eff 100%
    );
}

.fullwidth-section .container-fluid {
    padding: 0;
}

.fullwidth-image {
    position: relative;
    min-height: 640px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
}

.fullwidth-image h4.fg-text-light {
    font-size: 14px;
    font-weight: 500;
    line-height: 3;
    color: #ffffff;
}

.fullwidth-image h2.fg-text-light {
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
}

.articles-onnews-loop .blog-classic {
    margin-bottom: 30px;
}

.articles-onnews-loop .blog-classic .blog-classic-body-part .blog-classic-featured-image {
    border-radius: 6px;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper
+ .blog-classic-body {
    padding: 15px 0 20px 0;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper
+ .blog-classic-body .ffb-meta-data-2 {
    margin: 0 0 5px 0;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper
+ .blog-classic-body .blog-classic-title {
    font-size: 24px;
    line-height: 1.29;
    color: #364c69;

    margin: 0;
    min-height: 60px;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper {
    position: relative;
}

.articles-onnews-loop .blog-classic .blog-classic-body-part {
    overflow: hidden;
    border-radius: 6px;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper {
    position: relative;
    display: block;
    background-repeat: no-repeat;
    min-height: 190px;
    background-position: center;
    background-size: cover;
    border-radius: 6px;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper.no-thumb {
    background-image: url("../img/bg/hs-articles-thumb.jpg");
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper.for-single-news-item {
    min-height: 145px;
}

.articles-onnews-loop .blog-classic:hover .ff-post-featured-image-link-wrapper:after {
    opacity: 0;
}

.articles-onnews-loop h1 > a:hover,
.articles-onnews-loop h2 > a:hover,
.articles-onnews-loop h3 > a:hover,
.articles-onnews-loop h4 > a:hover,
.articles-onnews-loop h5 > a:hover,
.articles-onnews-loop h6 > a:hover {
    font-size: 24px;
    line-height: 1.29;
    color: #364c69;
    display: block;
}

.articles-onnews-loop h1 > a,
.articles-onnews-loop h2 > a,
.articles-onnews-loop h3 > a,
.articles-onnews-loop h4 > a,
.articles-onnews-loop h5 > a,
.articles-onnews-loop h6 > a {
    font-size: 24px;
    line-height: 1.29;
    color: #364c69;
    display: block;
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper:after {
    -webkit-transition: opacity 0.3s ease !important;
    -o-transition: opacity 0.3s ease !important;
    transition: opacity 0.3s ease !important;
    opacity: 1;
    content: '';
    position: absolute;
    top: 0;
    display: block;
    bottom: 0;
    border-radius: 6px;
    overflow: hidden;
    left: 0;
    right: 0;
    pointer-events: none;
    background-image: -webkit-linear-gradient(
            30deg,
            rgba(84, 159, 247, 0.15),
            rgba(185, 110, 255, 0.75)
    );
    background-image: -o-linear-gradient(
            30deg,
            rgba(84, 159, 247, 0.15),
            rgba(185, 110, 255, 0.75)
    );
    background-image: linear-gradient(
            60deg,
            rgba(84, 159, 247, 0.15),
            rgba(185, 110, 255, 0.75)
    );
}

.articles-onnews-loop .blog-classic .ff-post-featured-image-link-wrapper
+ .blog-classic-body .ff-meta-item {
    font-size: 14px;
    font-weight: 500;
    color: #7236fd;
    text-transform: uppercase;
}

.meta-single-post-wrap .blog-grid-supplemental-title {
    display: block;
}

.meta-single-post-wrap .ff-meta-item {
    display: block;
    text-align: center;

    font-size: 16px;
    color: #364c69;
    letter-spacing: -0.1px;
    line-height: 24px;
    font-weight: 500;
}

.meta-single-post-wrap .avatar {
    margin: 30px auto 20px auto;
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.meta-single-post-wrap .writtenby {
    display: block;
    font-weight: 400;
    border-top: solid 1px #e5e5e5;
    padding-top: 20px;
}

.meta-single-post-wrap .ffb-date-2 {
    display: block;
    font-weight: 400;
    color: #333333;
}

.meta-single-post-wrap {
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    padding-bottom: 20px;
    padding-top: 20px;
}







.col-actions-wrap {
    position: relative;
    padding-top: 225px;
}



.section-about-video-link {
    padding: 150px 0 120px 0;
}

.play-btn-svg {
    margin: 10px auto 35px auto;
}

.year-title.base-content-title {
    opacity: 0.6;
    font-size: 56px;
    font-weight: bold;
    line-height: 0.86;
    color: #ffffff;
}

.icon-wrap-svg.bg-none {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.section-with-content-icon-wrap.left-side {
    right: auto;
    left: -196px;
}

.row-electric {
    -webkit-animation: opacityblink 2s infinite ease-in;
    animation: opacityblink 2s infinite ease-in;
}

@-webkit-keyframes opacityblink {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

@keyframes opacityblink {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.wave-symbol-wrap {
    position: relative;
    min-height: 88px;
    clear: both;
}

.wave-symbol-wrap .base-content-title {
    margin-left: 100px;
    overflow: hidden;
    padding-top: 29px;
}

.wave-symbol-wrap .wave-symbol-wrap-icon {
    height: 88px;
    width: 88px;
    float: left;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.wave-bg-absolute-wrap {
    bottom: 0;
    padding: 0;
    margin: 100px 0 0 0;
    position: relative;
    pointer-events: none;
}

.wave-bg-absolute {
    height: 40px;
}

.wave-bg-absolute canvas {
    height: 40px;
    width: 100%;
}

.with-wave-bg {
    padding-bottom: 0 !important;
}

@-webkit-keyframes soundscale {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes soundscale {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.section-with-content-icon-wrap {
    height: 191px;
    width: 191px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    position: absolute !important;
    right: 5px;
    top: -77px;
}

.section-with-content-icon-wrap .fg-bg .fg-bg-type-image {
    -webkit-animation: soundscale 4s infinite linear;
    animation: soundscale 4s infinite linear;
}

.icon-wrap-svg {
    background-image: -webkit-linear-gradient(
            36deg,
            #549ff7 0%,
            #8587fb 48%,
            #b86fff 98%,
            #b96eff 100%
    );
    background-image: -o-linear-gradient(
            36deg,
            #549ff7 0%,
            #8587fb 48%,
            #b86fff 98%,
            #b96eff 100%
    );
    background-image: linear-gradient(
            54deg,
            #549ff7 0%,
            #8587fb 48%,
            #b86fff 98%,
            #b96eff 100%
    );
    -webkit-box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.19);
    box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.19);
    border-radius: 50%;
    height: 96px;
    width: 96px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
}

.icon-wrap-svg svg {
    margin: 0 auto;
}

.continuous-innovation-wrap {
    padding: 135px 0 115px 0;
}

.base-content-title.fg-text-dark {
    letter-spacing: -0.1px;
    color: #364c69 !important;
}

.base-content-title.fg-text-light {
    letter-spacing: -0.1px;
    color: #ffffff !important;
}

h3.base-content-title {
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 35px;
}

h2.base-content-title {
    font-size: 32px;
    line-height: 1.1;
    margin-bottom: 50px;
}

.section-with-content {
    margin: 100px 0;
    position: relative;
}

.base-content-wrapper.fg-text-dark {
    opacity: 0.85;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #364c69;
}

.base-content-wrapper.fg-text-light {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #ffffff;
}

.header-with-titlebar .breadcrumbs-v4-subtitle {
    display: none;
}

.header-with-titlebar .breadcrumbs-v4 {
    padding: 120px 0 60px;
}

.header-for-single-post.header-with-titlebar .breadcrumbs-v4 {
    padding: 220px 0 160px;
}

.header-with-titlebar .fg-bg .fg-bg-type-color {
    opacity: 0.6 !important;
    background-image: -webkit-linear-gradient(
            169deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
    background-image: -o-linear-gradient(
            169deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
    background-image: linear-gradient(
            281deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
}

.header-with-titlebar .fg-bg .fg-bg-type-parallax.fg-bg-layer {
    background-size: cover !important;
    background-position: center !important;
    background-attachment: initial;
}

.header-with-titlebar .breadcrumbs-v4-title {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.show-more-button a.btn-base-md {
    margin: 33px auto 61px auto;
    font-weight: 500;
    border: none;
    padding: 9px 34px;
    font-size: 16px;
    border-radius: 2px;
    background-image: -webkit-linear-gradient(168deg, #549ff8, #ba6eff);
    background-image: -o-linear-gradient(168deg, #549ff8, #ba6eff);
    background-image: linear-gradient(282deg, #549ff8, #ba6eff);
    -webkit-box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.show-more-button a.btn-base-md:hover {
    background-image: -webkit-linear-gradient(
            rgba(255, 255, 255, .2),
            rgba(255, 255, 255, .2)
    ),
    -webkit-linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)),
    -webkit-linear-gradient(165deg, #549ff8, #ba6eff);
    background-image: -o-linear-gradient(
            rgba(255, 255, 255, .2),
            rgba(255, 255, 255, .2)
    ),
    -o-linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)),
    -o-linear-gradient(165deg, #549ff8, #ba6eff);
    background-image: linear-gradient(
            rgba(255, 255, 255, .2),
            rgba(255, 255, 255, .2)
    ),
    linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)),
    linear-gradient(285deg, #549ff8, #ba6eff);
}

.research-item-wrap {
    overflow: hidden;
    clear: both;
}

.research-item-wrap .research-image {
    float: left;
    height: 80px !important;
    width: 80px !important;
    margin-right: 30px;
    border-radius: 6px;
    border: solid 1px #e5e5e5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.research-item-wrap .research-image-wrap {
    position: relative;
    float: left;
    height: 80px !important;
    width: 80px !important;
    margin-right: 30px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: solid 1px #e5e5e5;
}
.research-item-wrap .research-image-wrap .fg-bg .fg-bg-layer {
    background-size: cover !important;
}
.research-item-wrap .research-image-wrap img {
    display: block;
    width: 100%;
    height: 100%;
}
.research-item-wrap .research-image-wrap + .news-v3-content {
    margin-left: 110px;
}
.research-item-wrap .research-image + .news-v3-content {
    overflow: hidden;
    padding: 0 !important;
}

.bg-fullpercent .fg-bg .fg-bg-layer {
    background-size: 100% !important;
}

.latest-research {
    margin-bottom: 0 !important;
    padding-bottom: 65px;
    border-bottom: solid 1px #e5e5e5;
}

.research-item-wrap {
    padding: 40px 0 25px 0;
    border-bottom: solid 1px #e5e5e5;
}

.research-item-wrap .research-content-wrap {
    overflow: hidden;
}

.post-list-items-wrap .fg-blog-col-main {
    margin-bottom: 0;
}

.post-list-items-wrap .fg-blog-col-main:last-child .research-item-wrap {
    border-bottom: none;
}

.post-wrapper.post-list-item .news-v3-title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    color: #364c69 !important;
    text-align: left;
    margin-bottom: 10px;
}

.post-wrapper.post-list-item .ff-news-v3-meta-data {
    font-size: 14px;
    font-weight: 500;
    color: #7236fd;
}

.post-wrapper.post-list-item .news-v3-title > a {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    color: #364c69 !important;
}

.post-wrapper.post-list-item .news-v3-title > a:hover {
    opacity: 0.75;
}

.post-wrapper.post-list-item .ff-news-v3-post-content {
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: -0.1px;
    text-align: left;
    color: #546b85 !important;
}

.post-wrapper.post-list-item .ff-news-v3-post-content .post-content {
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #546b85 !important;
}

.research-content-wrap h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    color: #364c69 !important;
    text-align: left;
    margin-bottom: 10px;
}

.research-content-wrap p.fg-paragraph {
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: -0.1px;
    text-align: left;
    color: #546b85 !important;
}

.research-paper-row-wrap > .fg-bg {
    z-index: 1;
    pointer-events: none;
}

.research-paper-card p.fg-paragraph {
    opacity: 0.9;
    font-size: 14px !important;
    line-height: 1.64;
    letter-spacing: -0.1px;
    text-align: left;
    color: #ffffff !important;
}

.research-paper-card h3.fg-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.35;
    text-align: left;
    color: #ffffff;
    min-height: 54px;
}

.research-paper-card .icon-wrap svg {
    margin: 0 auto;
}

.research-paper-card .icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    margin: 0 auto 30px auto;
    width: 92px;
    border-radius: 50%;
    height: 92px;
    opacity: 0.85;
    background-color: #ffffff;
    -webkit-box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.12);
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.12);
}

.research-paper-card {
    -webkit-box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.19);
    box-shadow: 0 11px 22px 0 rgba(0, 0, 0, 0.12),
    0 7px 33px 0 rgba(130, 77, 191, 0.19);
    border-radius: 6px;
    min-height: 400px;
    padding: 30px;
    margin-bottom: 20px;
}

.research-paper-card.card-1 {
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #b571ff, #a776fd);
    background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #b571ff, #a776fd);
}

.research-paper-card.card-2 {
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #a577fd, #9a7efd);
    background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #a577fd, #9a7efd);
}

.research-paper-card.card-3 {
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -webkit-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -webkit-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: -o-linear-gradient(
            rgba(0, 0, 0, 0.01),
            rgba(0, 0, 0, 0.01)
    ),
    -o-linear-gradient(165deg, #987efd, #8b84fb);
    background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    linear-gradient(285deg, #987efd, #8b84fb);
}

.base-heading-papers {
    font-size: 23px;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: #364c69;
    margin: 65px auto;
}

.research-paper-slider .carousel-control span {
    background: none;
    color: #fff;
    font-size: 40px;
}

.research-paper-slider .carousel-control span:hover {
    background: none;
}

.research-paper-slider .caption-wrap {
    min-height: 456px;
    display: -webkit-box;
    padding-top: 35px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
}

.research-paper-slider .caption-wrap:before {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    bottom: 0;
    content: '';
    opacity: 0.9;
    background-image: -webkit-linear-gradient(
            160deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
    background-image: -o-linear-gradient(
            160deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
    background-image: linear-gradient(
            290deg,
            rgba(84, 159, 247, 0.2),
            rgba(120, 141, 250, 0.2) 36%,
            #ba6eff
    );
}

.research-paper-slider .caption-wrap:after {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    bottom: 0;
    content: '';
    opacity: 0.7;
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-linear-gradient(
            top,
            rgba(54, 76, 105, 0.21) 0%,
            #364c69 100%
    );
    background-image: -o-linear-gradient(
            top,
            rgba(54, 76, 105, 0.21) 0%,
            #364c69 100%
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(54, 76, 105, 0.21)),
            to(#364c69)
    );
    background-image: linear-gradient(
            -180deg,
            rgba(54, 76, 105, 0.21) 0%,
            #364c69 100%
    );
}

.research-paper-slider.no-mask .caption-wrap:before {
    display: none;
}

.research-paper-slider.no-mask .caption-wrap:after {
    display: none;
}

.learn-title-block > .fg-link-wrapper-el {
    padding-left: 100px;
    margin-bottom: 50px;
    position: relative;
    margin-left: -15px;
}

.learn-title-block {
    margin-top: 70px;
    padding-bottom: 60px;
}

.learn-title-block > .fg-link-wrapper-el svg {
    position: absolute;
    left: 20px;
    top: -20px;
}
.learn-title-block > .fg-link-wrapper-el p {
    margin: 0;
    line-height: 1 !important;
}

section.learn-block > svg {
    position: absolute;
    left: 21px;
    top: 45px;
}

section.learn-block #svginfoicon {
    left: 29px;
    top: 54px;
}

section.learn-block {
    padding-left: 130px;
    position: relative;
    border-radius: 6px;
}

.woocommerce-checkout.woocommerce-page .woocommerce-account-fields .create-account.custom-checkbox {
    position: relative;
}
.woocommerce-checkout.woocommerce-page .woocommerce-account-fields .create-account.custom-checkbox .woocommerce-form__input-checkbox {
    position: absolute;
    visibility: hidden;
    z-index: -5;
}
.woocommerce-checkout.woocommerce-page .woocommerce-account-fields .create-account.custom-checkbox::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 3;
}

.product[data-varsku=download] .quantity,
.woocommerce-variation-price,
.woosb-products .variations {
    display: none !important
}

.bundle-variant-radio {
    text-align: center
}

.bundled_product .attribute-options td.label,
.bundled_product .bundled_item_cart_content {
    display: none
}

.controll-wrapper span {
    cursor: pointer;
    color: #fff
}

.woocommerce-checkout .ywgc-gift-card-applied {
    display: none
}

.align-right {
    float: right;
    color: #9aa5b4;
    position: absolute;
    right: 0;
    top: 0
}

.ffb-id-1chg5nh8 {
    position: relative
}