.inner_banner {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
  height: 657px;
}

.allow-full-mp3 img.woocommerce-product-gallery__image {
  border: 1px solid green;
}

.store-shim {
  max-width: 1180px;
  margin: auto;
  padding: 0 12px;
  
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 240px 1fr;
  }

  & > div:nth-child(1) {
    display: none;
    margin-top: 24px;
    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    ul {
      padding-left: 12px;
    }

    li {
      list-style-type: none;
      margin: 4px 0;
    }
    span {
      color: #bebebe;
    }

    @media (min-width: 992px) {
      display: block;
    }
  }

  & > div:nth-child(2) {
    & > div:nth-child(4) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
      column-gap: 16px;
    }

    & > div.has-see-all {
      margin-bottom: 8px;
    }

    @media (min-width: 992px) {
      & > div.has-see-all {
        display: flex;
        & > div:first-child {
          flex: 1;
        }
      }
    }
  }
}

.woocommerce-checkout #payment ul.payment_methods li label[for="payment_method_stripe"] > img {
  max-width: 40px;
  margin: 0;
}

html {
  font-size: 16px;
}